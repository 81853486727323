import apiClient from '../../core/api/api-client.js';
import { toCamelCase } from '../../utils/strings.js';
import { downloadResponseAsFile } from '../file/file.service.js';

const billingAccountsBaseUrl = '/api/billing/accounts';
const postBillingUrl = `${billingAccountsBaseUrl}/billing-information`;
const getBillingInfoUrl = (accountId) =>
    `${billingAccountsBaseUrl}/${accountId}/billing-information`;
const getReimbursementUrl = (accountId) =>
    `${billingAccountsBaseUrl}/${accountId}/reimbursement`;
const accessManagementBaseUrl = '/api/access-management/v1/folders';
const platformAccountSettingsBaseUrl = '/api/tenants/account-features';
const getAdvenirUrl = (accountId) =>
    `/api/billing/account/${accountId}/advenir`;
const patchFolders = (accountId) => `${accessManagementBaseUrl}/${accountId}`;

const defaultPaginationParams = {
    page: 0,
    size: 50,
};

/**
 * Normalizes okta user roles for everon
 * @param {Object} user
 * @returns {Object}
 */
function normalizeUserFromOKTA(user = {}) {
    const { roles, ...rest } = user;

    if (roles) {
        return { roles: roles.map(toCamelCase), ...rest };
    }

    return user;
}

/**
 * In case the condition is not satisfied, a 204 is returned
 * @param {Object} response
 * @returns {boolean}
 */
function checkStatus(response) {
    return response.status === 200;
}

/**
 * Returns whether the account with provided identifier is a sub-account based on the existence of a parent account
 * identifier related to it
 * @param {string} id
 * @returns {Promise<boolean>}
 */
export async function isSubAccount(id) {
    let accountData;

    try {
        accountData = await getAccountData(id);
    } catch (error) {
        console.error(
            `Something went wrong getting the data of the account with ${id} id: ${error}`
        );
    }

    return Boolean(accountData?.parentId);
}

/**
 * Returns whether the account with provided identifier is an employee account.
 * @param {string} id
 * @param {Array} userPermissions
 * @returns {Promise<boolean>}
 */
export async function isEmployeeSubAccount(id, userPermissions = []) {
    let isEmployeeAccount;

    try {
        isEmployeeAccount = await getHasDriver(id);
    } catch (error) {
        isEmployeeAccount = false;

        console.error(
            `Something went wrong checking the type of the account with ${id} id: ${error}`
        );
    }

    return isEmployeeAccount;
}

/**
 * Returns accounts together wth pagination and sorting information
 * @param {Object} [params]
 * @returns {Promise.<Object>}
 */
export async function getAccounts(params) {
    const defaultParams = {
        ...defaultPaginationParams,
        size: 30,
        sort: 'name,asc',
    };

    const accounts = await apiClient.get(billingAccountsBaseUrl, {
        ...defaultParams,
        ...params,
    });

    accounts.content = accounts.content.filter((account) => account !== null);

    return accounts;
}

/**
 * Returns user's account billing details
 * @param {string} [accountId=me]
 * @returns {Promise.<Object>}
 */
export function getBillingInfo(accountId = 'me') {
    return apiClient.get(getBillingInfoUrl(accountId));
}

/**
 * Returns `true` if user's billing info is complete
 * @param {string} [accountId=me]
 * @returns {Promise}
 */
export function getBillingInfoStatus(accountId = 'me') {
    return apiClient
        .head(`${getBillingInfoUrl(accountId)}/status`)
        .then(checkStatus);
}

/**
 * Returns overview of billing details
 * @param {string} [accountId=me]
 * @returns {Promise.<Object>}
 */
export function getBillingOverview(accountId = 'me') {
    return apiClient.get(
        `${billingAccountsBaseUrl}/${accountId}/billing-information/overview`
    );
}

/**
 * Updates user's account billing details. Note that `PUT` to this endpoint does not accept data in the exact same shape as what we receive from its `GET`.
 * @param {Object} billingInfoUpdate
 * @param {string} [accountId=me]
 * @returns {Promise.<Object>}
 */
export function updateBillingInfo(billingInfoUpdate, accountId = 'me') {
    return apiClient.put(getBillingInfoUrl(accountId), billingInfoUpdate, {});
}

/**
 * Updates user's account billing details. Note that `PATCH` to this endpoint does not accept data in the exact same shape as what we receive from its `GET`.
 * @param {Object} billingInfoUpdate
 * @param {string} [accountId=me]
 * @returns {Promise.<Object>}
 */
export function saveBillingInfo(billingInfoUpdate, accountId = 'me') {
    const headers = {
        'Content-Type': 'application/merge-patch+json',
    };

    return apiClient.patch(
        getBillingInfoUrl(accountId),
        billingInfoUpdate,
        {},
        headers
    );
}

export function getHasDriver(accountId) {
    return apiClient
        .get(`/api/users/v1/accounts/${accountId}/has-driver`)
        .then(checkStatus);
}

/**
 * Updates user's account reimbursement settings
 * @param {Object} payload
 * @param {string} [accountId=me]
 * @returns {Promise.<Object>}
 */
export function updateReimbursementSettings(payload, accountId = 'me') {
    return apiClient.patch(
        getReimbursementUrl(accountId),
        payload,
        {},
        {
            accept: 'application/json',
            'Content-Type': 'application/merge-patch+json',
        }
    );
}

/**
 * Returns reimbursement settings part for a user of a given account
 * @param {string} [accountId=me]
 * @returns {Promise.<Object>}
 */
export function getReimbursementSettings(accountId = 'me') {
    return apiClient.get(getReimbursementUrl(accountId));
}

/**
 * Creates a new account.
 * @param {Object} data - account data payload
 * @returns {Promise<JSON>} - account data response
 */
export function createAccount(data) {
    return apiClient.post('/api/users/signup', data);
}

/**
 * Returns the account data object for a given account.
 * @param {string} accountId
 * @returns {Promise}
 */
export function getAccountData(accountId) {
    return apiClient.get(`${accessManagementBaseUrl}/${accountId}`, {
        expand: 'parentName',
    });
}

/**
 *  Triggers the account deletion process
 *  @param {string} accountId
 *  @returns {Promise}
 */
export function initiateAccountDeletion(accountId) {
    return apiClient.delete(`${accessManagementBaseUrl}/${accountId}`);
}

/**
 * Returns `true` if user's account type is business
 * @param {string} [accountId=me]
 * @returns {Promise}
 */
export function getBusinessAccountStatus(accountId = 'me') {
    return apiClient
        .head(`${billingAccountsBaseUrl}/${accountId}/business`)
        .then(checkStatus);
}

/**
 * Returns account feature flags map
 * @returns {Promise.<Object>}
 */
export function getAccountFeatureFlags() {
    return apiClient.get('/api/tenants/account-features');
}

/**
 * Returns feature flags map for specific account.
 * @param {string} accountId
 * @returns {Promise.<Object>}
 */
export function getFeatureFlagsForAccount(accountId) {
    return apiClient.get(`${platformAccountSettingsBaseUrl}/${accountId}`);
}

/**
 * Returns a paginated list of all accounts in the current tenant. Will return 403 if not called by a tenant level user
 * @param {Object} params
 * @returns {Promise}
 */
export function getAllAccounts(params) {
    const defaultParams = {
        ...defaultPaginationParams,
        sort: 'name,asc',
        level: 'all',
    };

    return apiClient.get(accessManagementBaseUrl, {
        ...defaultParams,
        ...params,
    });
}

/**
 * Creates an account with the specified name
 * @param {string} name
 * @returns {Promise}
 */
export function createAccountWithoutOwner(name) {
    return apiClient.post(accessManagementBaseUrl, { name });
}

/**
 * Updates user's account billing details. Note that `POST` to this endpoint does not accept data in the exact same shape as what we receive from its `GET`.
 * @param {Object} billingInfoUpdate
 * @returns {Promise.<Object>}
 */
export function createBillingInfo(billingInfoUpdate) {
    return apiClient.post(postBillingUrl, billingInfoUpdate, {});
}

/**
 * Returns if the given accountName is available or taken
 * @param {string} accountName
 * @returns {Promise<boolean>}
 */
export function isAccountNameAvailable(accountName) {
    return apiClient
        .get(`${accessManagementBaseUrl}:single?`, { name: accountName })
        .then(({ status }) => {
            return status === 204;
        });
}

/**
 * Updates advenir settings
 * @param {Object} payload
 * @param {string} accountId
 * @returns {Promise.<Object>}
 */
export function updateAdvenirSettings(payload, accountId) {
    return apiClient.patch(
        getAdvenirUrl(accountId),
        payload,
        {},
        {
            accept: 'application/json',
            'Content-Type': 'application/merge-patch+json',
        }
    );
}

/**
 * Returns advenir settings
 * @param {string} accountId
 * @returns {Promise.<Object>}
 */
export function getAdvenirSettings(accountId) {
    return apiClient.get(
        getAdvenirUrl(accountId),
        {},
        { accept: 'application/json' }
    );
}

/**
 * Returns a list of users (10 max.) that belong to a given account
 * @param {Object} params
 * @returns {Promise.<Array>}
 */
export function getAccountUsers(params) {
    const defaultParams = {
        ...defaultPaginationParams,
        size: 30,
        sort: 'email,asc',
    };

    const { page, size, sort, accountId } = params;

    return apiClient
        .get(`/api/users/search/account/${accountId}/users`, {
            ...defaultParams,
            page,
            size,
            sort,
        })
        .then((users) => {
            const content = users.content.map(normalizeUserFromOKTA);

            return { ...users, content };
        });
}

/**
 * Returns total number of sub-accounts on an account
 * @param {string} accountId
 * @returns {Promise.<Number>}
 */
export function getSubAccountCount(accountId) {
    const params = {
        ...defaultPaginationParams,
        size: 1,
        sort: 'name,asc',
    };

    return apiClient
        .get(`${accessManagementBaseUrl}/${accountId}/sub-folders`, params)
        .then((response) => {
            return response?.totalElements || 0;
        })
        .catch(() => {
            return 0;
        });
}

/**
 * update an accounts accountName
 * @param {string} id
 * @param {string} name
 * @returns {Promise}
 */
export function updateAccountName(id, name) {
    return apiClient.patch(
        patchFolders(id),
        { name },
        {},
        {
            accept: 'application/json',
            'Content-Type': 'application/merge-patch+json',
        }
    );
}

/**
 * Returns a paginated list of (direct) child accounts of a particular account
 * @param {string} accountId
 * @param {Object} params
 * @returns {Promise<JSON>}
 */
export function getSubAccounts(accountId, params) {
    const defaultParams = {
        ...defaultPaginationParams,
        sort: 'name,asc',
    };

    return apiClient.get(
        `${accessManagementBaseUrl}/${accountId}/sub-folders`,
        { ...defaultParams, ...params }
    );
}

/**
 * downloads a CSV file containing billing history information
 * @param {string} accountId
 */
export function downloadAccountBillingHistory(accountId) {
    apiClient
        .goFetch(`${billingAccountsBaseUrl}/history/${accountId}`)
        .then(downloadResponseAsFile);
}

/**
 * Updates all settings for a certain account
 * @param {Object.<string, boolean>} payload e.g. {chargingProfile: true, someOtherFeature: false}
 * @param {string} accountId
 * @returns {Promise}
 */
export function updateFeatureFlagsForAccount(payload, accountId) {
    return apiClient.put(
        `${platformAccountSettingsBaseUrl}/${accountId}`,
        payload
    );
}

/**
 * Returns account holder's data needed for asset activation flows.
 * It invokes the right method to get data from BE based on self or on behalf activation
 * @param {string} accountId
 * @param {boolean} onBehalf
 * @returns {Promise.<Object>}
 */
export function getAccountHolderData(accountId, onBehalf) {
    const dataPromise = onBehalf ? getAccountDataWithBillingId : getAccountData;

    return dataPromise(accountId).then(({ id, name, billingAccountId }) => ({
        id,
        name,
        billingAccountId,
    }));
}

/**
 * Returns account data including `billingAccountId`.
 * `billingAccountId` is the id of the account that is on charge of billing
 * for the received `accountId`.
 * @param {string} accountId
 * @returns {Promise.<Object>}
 */
export function getAccountDataWithBillingId(accountId) {
    const accountPromise = getAccountData(accountId);
    const billingAccountIdPromise = getBillingAccountId(accountId);

    return Promise.all([accountPromise, billingAccountIdPromise]).then(
        ([account, { billingAccountId }]) => ({
            ...account,
            billingAccountId,
        })
    );
}

/**
 * Returns the billing account ID for a given account.
 * @param {string} accountId
 * @returns {Promise}
 */
export function getBillingAccountId(accountId) {
    return apiClient.get(
        `${accessManagementBaseUrl}/${accountId}/billing-account`
    );
}

/**
 * Returns a paginated list of (direct) child accounts
 * @param {Object} params
 * @returns {Promise<JSON>}
 */
export function getChildAccounts(params) {
    const defaultParams = {
        ...defaultPaginationParams,
        sort: 'name,asc',
        level: 'children',
    };

    return apiClient.get(accessManagementBaseUrl, {
        ...defaultParams,
        ...params,
    });
}
